import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import AcademyQuery from './AcademyQuery.gql';
import Image from '@jetshop/ui/Image';
import { styled } from "linaria/react";
import { css } from "linaria";
import { AcademyContainer } from './AcademyPage';
import AcademySidebar from './AcademySidebar';
import { theme } from "../Theme";
import { Link } from 'react-router-dom';
import { Price } from '../Price';
import Head from '@jetshop/core/components/Head';

const AcademyArticle = styled('article')`
  background: #fff;
  font-family: ${theme.fonts.primary};
  margin-left: 3rem;
  width: calc(80% - 3rem);
  .youtube-embed {
    display: none;
  }

  h1 {
    font-size: 1.75rem;
    letter-spacing: 1px;
    line-height: 1.4;
  }

  h3 {
    letter-spacing: 1px;
    padding-top: 10px;
  }

  h4 {
    font-size: 1rem;
    letter-spacing: 1px;
  }

  p {
    font-size: 0.85rem;
    margin-bottom: 0.5rem;
  }

  ul {
    font-size: 0.85rem;
    list-style-type: disc;
    margin-left: 1rem;
  }

  td {
    font-size: 0.85rem;
    strong {
    }
  }

  a {
    color: ${theme.colors.accent};
    text-decoration: none;
    transition: all ease 0.3s;
    &:hover {
      color: #000;
      transition: all ease 0.3s;
    }
  }

  ${theme.below.md} {
    margin: 0;
    margin-top: 5rem;
    width: 100%;
  }
`;

const VideoHolder = styled('div')`
  margin-bottom: 1rem;
  position: relative;
  padding-bottom: 53.55%;
  padding-top: 25px;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const ArticleContent = styled('div')`
  p:first-of-type > img:first-of-type {
    display: none;
  }
`;

const Products = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 3rem;
  margin-left: -1rem;
  margin-right: -1rem;
  width: 100%;
  ${theme.below.xl} {
    justify-content: center;
  }
`;

const Product = styled('div')`
  background: ${theme.colors.background};
  border: 1px solid ${theme.colors.lightgrey};
  height: auto;
  margin: 1rem;
  transition: all ease 0.3s;
  width: 230px;
  &:hover {
    box-shadow: 0px 0px 5px 1px ${theme.colors.lightgrey} !important;
  }
  img {
    background: #fff;
    padding: 0rem 1rem;
  }
`;

const ProductName = styled('div')`
  font-family: ${theme.fonts.primary};
  font-size: 14px;
  margin-top: 1rem;
  min-height: 42px;
  padding: 0 1rem;
  text-align: center;
  a {
    color: red !important;
  }
`;

const PriceStyle = css`
  font-weight: 700;
  padding: 0 1rem 1rem 1rem;
  text-align: center;
`;

const SingleAcademy = ({ location: { pathname } }) => {
  const [featuredImage, setFeaturedImage] = useState([]);
  const [featuredVideo, setFeaturedVideo] = useState(null);
  const [post, setPost] = useState(null);

  const { data, loading, error } = useQuery(AcademyQuery, {
    variables: {
      pathname,
    },
  });

  useEffect(() => {
    if (data && data?.route) {
      setPost(data.route.object);
    }

    if (
      data &&
      data?.route?.object?.content?.match(/<img [^>]*src="[^"]*"[^>]*>/gm)
    ) {
      const sources = data?.route?.object?.content
        .match(/<img [^>]*src="[^"]*"[^>]*>/gm)
        .map((img) => {
          return img
            .replace(/.*src="([^"]*)".*/, '$1')
            .replace('medium', 'original');
        });
      setFeaturedImage(sources);
    }

    if (
      data &&
      data?.route?.object?.content?.match(
        /(?:https?:\/\/|www\.|m\.|^)youtu(?:be\.com\/watch\?(?:.*?&(?:amp;)?)?v=|\.be\/)([\w‌​\-]+)(?:&(?:amp;)?[\w\?=]*)?/
      )
    ) {
      const videoLink = data.route.object.content.match(
        /(?:https?:\/\/|www\.|m\.|^)youtu(?:be\.com\/watch\?(?:.*?&(?:amp;)?)?v=|\.be\/)([\w‌​\-]+)(?:&(?:amp;)?[\w\?=]*)?/
      );
      setFeaturedVideo(videoLink.length > 1 ? videoLink[1] : null);
    }
  }, [data]);

  if (error) return null;
  if (loading || !post) return null;

  return (
    <AcademyContainer>
      <Head data={post.head} />
      <AcademySidebar path={pathname.split('/')[1]} pathname={pathname} />
      <AcademyArticle>
        {featuredVideo && (
          <VideoHolder>
            <iframe
              width="560"
              height="315"
              src={'https://www.youtube.com/embed/' + featuredVideo}
              frameborder="0"
              allowfullscreen
            />
          </VideoHolder>
        )}
        {featuredImage[0] && <Image aspect="2:1" src={featuredImage[0]} />}

        <h1>{post.name}</h1>
        <ArticleContent dangerouslySetInnerHTML={{ __html: post.content }} />
        <Products>
          {post?.products?.result?.map((result) => (
            <Link
              to={result.primaryRoute.path}
              key={result.articleNumber}
              style={{ color: 'black' }}
            >
              <Product>
                <Image
                  aspect="1:1"
                  src={result?.images[0]?.url}
                  alt={result?.images[0]?.alt}
                />
                <ProductName>{result.name}</ProductName>
                <Price
                  price={result.price}
                  previousPrice={result.previousPrice}
                  className={PriceStyle}
                />
              </Product>
            </Link>
          ))}
        </Products>
      </AcademyArticle>
    </AcademyContainer>
  );
};

export default SingleAcademy;
