import React from 'react';
import { Link } from 'react-router-dom';
import { theme } from "../Theme";
import { styled } from "linaria/react";
import Image from '@jetshop/ui/Image';
import t from '@jetshop/intl';

const Wrapper = styled('li')`
  margin: 1rem;
  a {
    color: #000;
    text-decoration: none;
  }
  h2 {
    font-size: 1.2rem;
  }

  span {
    font-size: 0.85rem;
  }
`;

const Text = styled('div')`
  background: ${theme.colors.background};
  padding: 1rem;
  p {
    display: block;
    min-height: 72px;
  }
`;

const ReadMore = styled('button')`
  background-color: transparent;
  color: ${theme.colors.accent};
  font-size: 0.85rem;
  font-weight: bold;
  margin-top: 0.5rem;
  outline: none;
  text-transform: uppercase;
`;

const decodeHtml = (html) => {
  if (typeof document !== 'undefined') {
    var tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    if (tmp.getElementsByClassName('youtube-embed').length > 0) {
      tmp.getElementsByClassName('youtube-embed')[0].remove();
    }
    return tmp.textContent || tmp.innerText || '';
  }
  return null;
};

const AcademyCard = ({ post }) => {
  if (!post) return null;
  return (
    <Wrapper>
      <Link to={post.primaryRoute.path}>
        <Image src={post?.images[0]?.url} />
        <Text>
          <h2>{post.name}</h2>
          <span>{post.parent.name}</span>
          <p>{decodeHtml(post.content)?.substr(0, 100)}...</p>
          <ReadMore>{t('Read more')}</ReadMore>
        </Text>
      </Link>
    </Wrapper>
  );
};

export default AcademyCard;
