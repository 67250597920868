import React from 'react';
import { theme } from "../Theme";

import { styled } from "linaria/react";
import AcademyCard from './AcademyCard';

const Wrapper = styled('ul')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  li {
    width: calc(33.333% - 2rem);
    ${theme.below.lg} {
      width: calc(50% - 2rem);
    }
    ${theme.below.md} {
      width: 100%;
    }
  }
`;

const AcademyGrid = ({ posts }) => {
  if (!posts) return null;
  return (
    <Wrapper>
      {posts.map((post, index) => {
        return <AcademyCard post={post} key={index} />;
      })}
    </Wrapper>
  );
};

export default AcademyGrid;
