import React from 'react';

import { useQuery } from 'react-apollo';
import AcademyQuery from './AcademyQuery.gql';

import { styled } from "linaria/react";

import { theme } from "../Theme";
import Head from '@jetshop/core/components/Head';

import AcademySidebar from './AcademySidebar';
import AcademyGrid from './AcademyGrid';
import MaxWidth from '../Layout/MaxWidth';

export const AcademyContainer = styled(MaxWidth)`
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 4rem;
  margin-bottom: 4rem;
  aside {
    width: 20%;
  }
  > ul {
    margin-left: 2rem;
    width: calc(80% - 2rem);
  }

  ${theme.below.md} {
    flex-direction: column;
    margin-top: 0.5rem;
    margin-bottom: 4rem;
    aside {
      width: 100%;
    }
    > ul {
      margin-left: 0;
      width: 100%;
    }
  }
`;

const AcademyPage = ({ location: { pathname }, match: { path }, ...props }) => {
  const { data, loading, error } = useQuery(AcademyQuery, {
    variables: {
      pathname
    }
  });
  if (error) return null;
  if (loading) return null;
  const {
    route: { object }
  } = data;
  const category = object;
  var posts = [];
  if (path === pathname) {
    for (let i = 0; i < category.subcategories.length; i++) {
      const subCat = category.subcategories[i];
      posts = posts.concat(subCat.subcategories);
    }
  } else {
    posts = category.subcategories;
  }

  return (
    <AcademyContainer>
      <Head data={category.head} />
      <AcademySidebar path={path} pathname={pathname} />

      <AcademyGrid posts={posts} />
    </AcademyContainer>
  );
};
export default AcademyPage;
